import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user_data: {},
    client_id: '',
    theme: 'light',
    is_main_user: true,
    client_list: [],
    client_data: {},
    company_branding: {},
}

export const commonSlice = createSlice({
    name: 'userName',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.user_data = action.payload
            if (parseInt(action.payload?.is_admin) === 1) {
                state.is_main_user = true
            } else {
                state.is_main_user = false
            }
        },
        setInitialUserData: (state, action) => {
            state.user_data = {}
        },
        setTheme: (state, action) => {
            state.theme = action.payload
        },
        setClientId: (state, action) => {
            state.client_id = action.payload
        },
        setInitialClientId: (state, action) => {
            state.client_id = ''
        },
        setClientList: (state, action) => {
            state.client_list = action.payload
        },
        setClientData: (state, action) => {
            state.client_data = action.payload
        },
        setCompanyBranding: (state, action) => {
            state.company_branding = action.payload
        },
        setInitialClientData: (state, action) => {
            state.client_data = {}
        }
    }
})

// Action creators are generated for each case reducer function
export const { setUserData, setInitialUserData, setTheme, setClientId, setInitialClientId, setClientList, setClientData, setCompanyBranding, setInitialClientData } = commonSlice.actions

export default commonSlice.reducer